import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";

export const Navbar = () => {
  return (
    <section className={styles.navbarContainer}>
      <div className={styles.navBarIconContainer}>
        <Link to="/coach/disclaimer">
          <p>
            <i className="fa-solid fa-user-doctor"></i>
          </p>
        </Link>
      </div>
      <div className={styles.navBarIconContainer}>
        <Link to="/home">
          <i className="fa-solid fa-house"></i>
        </Link>
      </div>
      <div className={styles.navBarIconContainer}>
        <Link to="/scanner/warning">
          <p>
            <i className="fa-solid fa-video"></i>
          </p>
        </Link>
      </div>
    </section>
  );
};

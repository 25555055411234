import { useEffect, useState } from "react";
import { StoredState, readState, subscribe } from "../lib/storedState";

export const useStoredState = (): StoredState => {
  const [storedState, setStoredState] = useState<StoredState>(readState());

  useEffect(() => {
    const unsubscribe = subscribe(setStoredState);
    return unsubscribe;
  }, []);

  return storedState;
};

import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import styles from "./TellCoachGoals.module.css";
import Logo from "../../../assets/logo-color.png";
import { storeTellCoachGoals } from "../../../lib/storeFunctions";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";

const ZTellCoach = z.object({
  tellCoachGoals: z.string().optional(),
});

export const TellCoachGoals = () => {
  const navigate = useNavigate();
  const [tellCoachGoals, setTellCoachGoals] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleFieldChangeText: ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    const { name, value } = event.target;
    if (name === "tellCoachGoals") return setTellCoachGoals(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zTellCoach = ZTellCoach.safeParse({
      tellCoachGoals,
    });
    if (!zTellCoach.success) {
      setShowErrors(true);
      return;
    }

    storeTellCoachGoals({
      tellCoachGoals: tellCoachGoals ? tellCoachGoals : "",
    });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("TellCoachGoals"));
  };

  const zTellCoach = ZTellCoach.safeParse({
    tellCoachGoals,
  });
  const tellCoachGoalsErrors = getErrors(zTellCoach, ["tellCoachGoals"]);

  return (
    <section className={styles.tellCoachGoalsContainer}>
      <figure className={styles.tellCoachGoalsLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        id="tell-coach-form"
        className={styles.tellCoachGoalsForm}
      >
        <div className={styles.tellCoachGoalsFormTextarea}>
          <label htmlFor="tell-coach">
            Is there anything else you'd like to tell Coach360 about your health
            goals or challenges?
          </label>
          <textarea
            name="tellCoachGoals"
            id="tell-coach"
            value={tellCoachGoals}
            onChange={handleFieldChangeText}
            placeholder="Write over here..."
          />
          <Errors show={showErrors} errors={tellCoachGoalsErrors} />
        </div>
      </form>
      <button
        type="submit"
        className={styles.tellCoachGoalsNextButton}
        form="tell-coach-form"
      >
        Next
      </button>
    </section>
  );
};

import styles from "./SymptomsList.module.css";
import Logo from "../../../assets/logo-color.png";
import { ChangeEventHandler, useState } from "react";

const POSSIBLE_SYMPTOMS = [
  "Fever or Chills",
  "Cough",
  "Shortness fo breath or difficulty breathing",
  "Fatigue",
  "Muscle or body aches",
  "Headache",
  "New loss of taste or smell",
  "Sore throat",
  "Congestion or runny nose",
  "Nausea or vomiting",
  "Diarrhea",
  "None",
] as const;

export const SymptomsList = () => {
  const [symptoms, setSymptoms] = useState([] as string[]);

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValues = new Set(symptoms);
    const { id, checked } = event.target;
    if (checked) {
      if (id === "Select all") {
        POSSIBLE_SYMPTOMS.filter((i) => i !== "None").forEach((i) =>
          currentValues.add(i),
        );
      }

      if (id === "None") {
        currentValues.clear();
      } else {
        currentValues.delete("None");
      }

      currentValues.add(id);
    } else {
      currentValues.delete("Select all");
      currentValues.delete(id);
    }
    setSymptoms([...currentValues]);
  };

  return (
    <section className={styles.symptomsListContainer}>
      <figure className={styles.symptomsListLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form>
        <h2>Symptoms</h2>
        <div className={styles.symptomsCheckboxes}>
          {POSSIBLE_SYMPTOMS.map((symptom) => (
            <div key={symptom} className={styles.symptomCheckbox}>
              <input
                type="checkbox"
                id={symptom}
                name="symptom"
                value={symptom}
                checked={Boolean(symptoms.find((i) => i === symptom))}
                onChange={handleFieldChange}
              />
              <label htmlFor={symptom}>{symptom}</label>
            </div>
          ))}
        </div>
      </form>
      <a href="/home" className={styles.symptomButton}>
        Submit
      </a>
    </section>
  );
};

import styles from "./ScannerWarning.module.css";
import Logo from "../../../assets/logo-color.png";

export const ScannerWarning = () => {
  return (
    <section className={styles.scannerWarningContainer}>
      <figure className={styles.scannerWarningLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <h1>
        Please, remain seated 5 minutes before the assessment and ensure your
        face is well lit from the front; try to avoid shadows and movement
        during the reading
      </h1>
      <a href="/scanner/video">Continue</a>
    </section>
  );
};

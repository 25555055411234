import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useBasicInformation } from "./useBasicInformation";
import { useStoredState } from "./useStoredState";
import { resumeBasicInformationScreen } from "../screens/basicInformation/nextBasicInformationScreen";
import { usePartialBasicInformation } from "./usePartialBasicInformation";

export type NavigationSections = "welcome" | "basicInformation" | "complete";

// signedIn|completeBi|then navigate to
// false   |false     |welcome
// false   |true      |welcome
// true    |false     |resume basicInformation
// true    |true      |home
export const useNavigateUser = (section: NavigationSections): void => {
  const navigate = useNavigate();
  const { jwt } = useStoredState();
  const partialBasicInformation = usePartialBasicInformation();
  const completeBasicInformation = useBasicInformation();

  useEffect(() => {
    const signedIn = Boolean(jwt);

    if (section !== "welcome" && !signedIn) {
      return navigate("/");
    }

    if (
      section !== "basicInformation" &&
      signedIn &&
      !completeBasicInformation &&
      partialBasicInformation
    ) {
      const [, basicInformation] = partialBasicInformation;
      return navigate(resumeBasicInformationScreen(basicInformation));
    }

    if (section !== "complete" && signedIn && completeBasicInformation) {
      return navigate("/home");
    }
  }, [
    completeBasicInformation,
    section,
    jwt,
    navigate,
    partialBasicInformation,
  ]);
};

const welcomeOrder: Record<string, string> & Object = {
  Welcome: "/",
  WhatWeDo: "/what-we-do",
  HowCanWeHelp: "/how-can-we-help",
  NotificationPermission: "/notification-permission",
};

export const nextWelcomeScreen = (current: string): string => {
  if (!(current in welcomeOrder))
    throw new Error(`Unexpected screen: ${current}`);

  const indexedByScreen: Record<string, number> = [
    ...Object.keys(welcomeOrder).entries(),
  ].reduce((memo, [i, screen]) => ({ ...memo, [screen]: i }), {});
  const indexAndPath: Record<string, string> = [
    ...Object.values(welcomeOrder).entries(),
  ].reduce((memo, [i, path]) => ({ ...memo, [i]: path }), {});

  const currentIndex = indexedByScreen[current];
  const nextIndex = currentIndex + 1;
  if (nextIndex >= Object.keys(welcomeOrder).length) {
    return "/sign-up";
  }

  return indexAndPath[nextIndex];
};

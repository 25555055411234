import styles from "./Disclaimer.module.css";
import Logo from "../../../assets/logo-color.png";
import { Navbar } from "../../../components/Navbar";
import { useState } from "react";

export const Disclaimer = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  const handleAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "accept") {
      setIsAccepted(true);
    }
  };

  return (
    <section className={styles.disclaimerMainContainer}>
      <figure className={styles.disclaimerLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.disclaimerMessageContainer}>
        <h2>Link with HealthLink</h2>
        <p>
          Before engaging with the AI-enabled health coach, please note that the
          information provided is not intended to replace professional medical
          advice. We recommend consulting with a qualified healthcare provider
          for personalized medical guidance. Please, be aware that HealthLink360
          is currently in the testing phase, and any insights or recommendations
          should be considered as educational and not medical advice.
        </p>
        <label className={styles.acceptRadio}>
          <input
            type="radio"
            value="accept"
            onChange={(event) => handleAccept(event)}
          />
          I accept
        </label>
        <a
          href="/coach/ai-coach"
          className={
            isAccepted
              ? styles.continueButtonActive
              : styles.continueButtonDeactivate
          }
        >
          Continue
        </a>
      </div>
      <Navbar />
    </section>
  );
};

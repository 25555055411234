import { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ScannerVideo.module.css";
import { Wave } from "../../../components/Wave";

export const ScannerVideo = () => {
  const [isPlaying, setIsPlaying] = useState<Boolean>(false);
  const videos = createRef<HTMLVideoElement>();
  const navigate = useNavigate();

  const navigateResults = () => navigate("/scanner/results");

  useEffect(() => {
    const stream = async () =>
      await navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" }, audio: false })
        .then((mediaStream) => {
          if (!videos) return;
          if (!videos.current) return;

          videos.current.srcObject = mediaStream;
          videos.current.onloadedmetadata = function (e) {
            if (!videos.current) return;
            videos.current.play();
            setIsPlaying(true);
          };
        });
    stream();
    setTimeout(() => {
      navigateResults();
    }, 10e3);
  });

  return (
    <section className={styles.scannerContainer}>
      <div className={styles.faceScannerContainer}>
        <span className={styles.scannerOval}></span>
        <video autoPlay ref={videos} className={styles.videoTag}></video>
        <div className={styles.instructions}>
          <div className={styles.lineContainer}>{isPlaying && <Wave />}</div>
          <p>Place your face inside the outlined oval</p>
        </div>
      </div>
    </section>
  );
};

import { z } from "zod";

export const getErrors = (
  safeParsed: z.SafeParseReturnType<unknown, unknown>,
  path: string[],
): string[] => {
  if (safeParsed.success) return [];

  return safeParsed.error.issues
    .filter((issue) => issue.path.join(".").startsWith(path.join(".")))
    .map((issue) => issue.message);
};

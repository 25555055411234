import styles from "./AICoachChat.module.css";

export const AICoachChat = () => {
  return (
    <section className={styles.chatbotContainer}>
      <div className={styles.responseContainer}>
        <div>
          <p>Start messaging our AI Coach360</p>
        </div>
        <form className={styles.keyboardContainer}>
          <textarea></textarea>
          <button type="submit" className={styles.buttonMessage}>
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </form>
      </div>
    </section>
  );
};

import { FormEventHandler, useState } from "react";
import styles from "./NutritionEntryTime.module.css"
import { FoodDetail, storeNutritionDetail } from "../../../lib/nutrition";

export type FoodDescription = {
  name: string;
  amount: string[];
}

export const NutritionEntryTime = ({
  foodTime,
  foodDescription,
 }: { foodTime: string, foodDescription: FoodDescription[] }) => {
  const [portion, setPortion] = useState<string>("");
  const [foodList, setFoodList] = useState<FoodDetail[]>([]);

  const handleSelectedFoods = ({ name, time }: {name: string, time: string}) => {
    setFoodList([...foodList, { time, foodDetail: { foodTime: time, foodName: name, quantity: portion } }]);
    storeNutritionDetail({ time, foodDetail: { foodTime: time, foodName: name, quantity: portion } });
  }

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    setFoodList([])
  }

  
  return (
    <div className={styles.nutritionEntryMainContainer}>
      <form className={styles.nutritionEntryTimeContainer} onSubmit={handleSubmit}>
        <h3>{foodTime}</h3>
        <div className={styles.nutritionEntryList}>
          {foodDescription.map((food) => (
            <div key={food.name} className={styles.nutritionEntryItem}>
              <select onChange={(event) => setPortion(event.target.value)}>
                {food.amount.map((amount) => (
                  <option key={amount} value={amount}>
                    {amount}
                  </option>
                  ))}
              </select>
              <label>
                {food.name}
              </label>
              <button type="button" onClick={() => handleSelectedFoods({ name: food.name, time: foodTime })} className={styles.addFood}>+</button>
            </div>
          ))}
        </div>
        <button className={styles.nutritionEntryAddFood} type="submit">
          Add to {foodTime.toLocaleLowerCase()}
          {foodList && (
            <span className={styles.foodListNumber}>{foodList.length}</span>
          )}
        </button>
      </form>
    </div>
  )
}

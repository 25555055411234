import styles from "./AlreadyAccount.module.css";

export const AlreadyAccount = () => {
  return (
    <div className={styles.alreadyContainer}>
      <p>
        Already have an account?
        <a className={styles.alreadySignUp} href="/sign-in">
          Sign in
        </a>
      </p>
    </div>
  );
};

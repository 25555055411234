import { FormEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { useBasicInformation } from "../../../hooks/useBasicInformation";
import styles from "./Thanks.module.css";
import Logo from "../../../assets/logo-color.png";

export const Thanks = () => {
  const navigate = useNavigate();
  const completeBasicInformation = useBasicInformation();
  if (!completeBasicInformation) {
    return <div>Complete basic information expected</div>;
  }

  const [, basicInformation] = completeBasicInformation;
  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    navigate(nextBasicInformationScreen("Thanks"));
  };

  return (
    <section className={styles.thanksContainer}>
      <figure className={styles.thanksLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        className={styles.thanksForm}
        id="thanks-form"
      >
        <p>
          Thanks for chatting with me today, {basicInformation.firstName}. It's
          been awesome getting to know you better. If you ever need anything,
          I'm just a tap away. Take care!
        </p>
      </form>
      <button
        type="submit"
        className={styles.thanksNextButton}
        form="thanks-form"
      >
        Next
      </button>
    </section>
  );
};

import { PartialBasicInformation } from "../../lib/basicInformation";

const fieldsByComponent = {
  Names: ["firstName", "lastName"],
  Basic: ["email", "dob", "gender", "zipCode"],
  HealthAndWellness: ["weight", "height", "activityLevel"],
  Habits: ["stressLevel", "smoke", "alcohol"],
  FoodSensitivities: ["foodSensitivities", "foodSensitivitiesDetails"],
  SDOH: ["nutritiousFood", "livingEnvironment"],
  ResourcesOptions: ["resourcesOptions"],
  ComplexHealth: ["checkUpsFrequency"],
  Medications: ["medications"],
  ChronicConditions: ["chronicConditions"],
  HealthGoals: ["healthGoals"],
  TellCoachGoals: ["tellCoachGoals"],
  Thanks: [],
};

const basicInformationOrder = {
  Names: "/bi/names",
  Basic: "/bi/basic",
  HealthAndWellness: "/bi/health-and-wellness",
  Habits: "/bi/habits",
  FoodSensitivities: "/bi/food-sensitivities",
  SDOH: "/bi/social-determinants-of-health",
  ResourcesOptions: "/bi/resources",
  ComplexHealth: "/bi/complex-health",
  Medications: "/bi/medications",
  ChronicConditions: "/bi/chronic-conditions",
  HealthGoals: "/bi/health-goals",
  TellCoachGoals: "/bi/tell-coach-goals",
  Thanks: "/bi/thanks",
};

export const nextBasicInformationScreen = (current: string): string => {
  if (!(current in basicInformationOrder)) {
    throw new Error(`Unexpected screen: ${current}`);
  }

  const indexedByScreen: Record<string, number> = [
    ...Object.keys(basicInformationOrder).entries(),
  ].reduce((memo, [i, screen]) => ({ ...memo, [screen]: i }), {});
  const indexAndPath: Record<string, string> = [
    ...Object.values(basicInformationOrder).entries(),
  ].reduce((memo, [i, path]) => ({ ...memo, [i]: path }), {});

  const currentIndex = indexedByScreen[current];
  const nextIndex = currentIndex + 1;
  if (nextIndex >= Object.keys(basicInformationOrder).length) {
    return "/home";
  }

  return indexAndPath[nextIndex];
};

export const resumeBasicInformationScreen = (
  basicInformation: PartialBasicInformation,
): string => {
  const entry = Object.entries(fieldsByComponent).find(([_, fields]) =>
    fields.some(
      (field) =>
        basicInformation[field as keyof PartialBasicInformation] === undefined,
    ),
  );

  if (entry) {
    const component = entry[0] as keyof typeof basicInformationOrder;
    return basicInformationOrder[component];
  }

  return "/home";
};

import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { usePartialBasicInformation } from "../../../hooks/usePartialBasicInformation";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeBasicOnboarding } from "../../../lib/storeFunctions";
import styles from "./Basic.module.css";
import Logo from "../../../assets/logo-color.png";

const ZBasic = z.object({
  email: z.string().email().trim(),
  dob: z.coerce.date(),
  gender: z.enum(["male", "female", "non-binary", "transgender", "other"]),
  zipCode: z
    .string()
    .regex(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid ZIP Code")
    .trim(),
});

export const Basic = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().slice(0, 10);
  const [dob, setDob] = useState(currentDate);
  const [gender, setGender] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const partialInformation = usePartialBasicInformation();
  if (!partialInformation) {
    return <div>Complete basic information expected</div>;
  }

  const [email, _] = partialInformation;

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    if (name === "dob") return setDob(value);
    if (name === "zipCode") return setZipCode(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zBasic = ZBasic.safeParse({
      email,
      dob,
      gender,
      zipCode,
    });
    if (!zBasic.success) {
      setShowErrors(true);
      return;
    }

    storeBasicOnboarding(zBasic.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("Basic"));
  };

  const zBasic = ZBasic.safeParse({
    email,
    dob,
    gender,
    zipCode,
  });
  const emailErrors = getErrors(zBasic, ["email"]);
  const dobErrors = getErrors(zBasic, ["dob"]);
  const genderErrors = getErrors(zBasic, ["gender"]);
  const zipCodeErrors = getErrors(zBasic, ["zipCode"]);

  return (
    <section className={styles.basicContainer}>
      <figure className={styles.basicLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        className={styles.basicForm}
        id="basic-info"
      >
        <h2>Onboarding</h2>
        <div className={styles.basicInputContainer}>
          <input
            name="email"
            placeholder="Email Address"
            type="email"
            value={email}
            readOnly
          />
          <Errors show={showErrors} errors={emailErrors} />
        </div>
        <div className={styles.basicInputContainer}>
          <input
            name="dob"
            type="date"
            value={dob}
            onChange={handleFieldChange}
            max={currentDate}
          />
          <Errors show={showErrors} errors={dobErrors} />
        </div>
        <div className={styles.basicInputContainer}>
          <select
            name="gender"
            onChange={(event) => setGender(event?.target.value)}
          >
            <option value="">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="non-binary">Non-binary</option>
            <option value="transgender">Transgender</option>
            <option value="other">Other</option>
          </select>
          <Errors show={showErrors} errors={genderErrors} />
        </div>
        <div className={styles.basicInputContainer}>
          <input
            name="zipCode"
            placeholder="ZIP Code"
            type="number"
            value={zipCode}
            onChange={handleFieldChange}
          />
          <Errors show={showErrors} errors={zipCodeErrors} />
        </div>
      </form>
      <button
        type="submit"
        className={styles.basicNextButton}
        form="basic-info"
      >
        Next
      </button>
    </section>
  );
};

import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeChronicConditions } from "../../../lib/storeFunctions";
import styles from "./ChronicConditions.module.css";
import Logo from "../../../assets/logo-color.png";

const POSSIBLE_CONDITIONS = [
  "Diabetes",
  "Pre-Diabetes",
  "Diabetes 1",
  "Diabetes 2",
  "Hypertension",
  "Cancer",
  "Substance Abuse",
  "HIV+",
  "Obesity",
  "Mental Illness",
] as const;

const ZChronicConditions = z.object({
  has: z.boolean(),
  chronicConditions: z.array(z.enum(POSSIBLE_CONDITIONS)),
});

export const ChronicConditions = () => {
  const navigate = useNavigate();
  const [has, setHas] = useState(null as boolean | null);
  const [chronicConditions, setChronicConditions] = useState([] as string[]);
  const [showErrors, setShowErrors] = useState(false);

  const handleHas: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const { value } = event.currentTarget.dataset;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }
    const newHas = value === "true";
    if (!newHas) setChronicConditions([]);
    setHas(newHas);
  };

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValues = new Set(chronicConditions);
    const { id, checked } = event.target;
    if (checked) {
      currentValues.add(id);
    } else {
      currentValues.delete(id);
    }
    setChronicConditions([...currentValues]);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zChronicConditions = ZChronicConditions.safeParse({
      has,
      chronicConditions,
    });
    if (!zChronicConditions.success) {
      setShowErrors(true);
      return;
    }

    const rawConditions = zChronicConditions.data.chronicConditions;

    storeChronicConditions({
      chronicCondition: rawConditions ? rawConditions : [],
    });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("ChronicConditions"));
  };

  const zChronicConditions = ZChronicConditions.safeParse({
    has,
    chronicConditions,
  });
  const hasErrors = getErrors(zChronicConditions, ["has"]);
  const chronicConditionsErrors = getErrors(zChronicConditions, [
    "chronicConditions",
  ]);
  return (
    <section className={styles.chronicConditionsContainer}>
      <figure className={styles.chronicConditionsLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        className={styles.chronicConditionsForm}
        id="chronic-conditions-form"
      >
        <h2>Complex Health</h2>
        <h3>
          Are you currently managing any chronic conditions? (Select all that
          apply)
        </h3>
        <div className={styles.chronicConditionsFormButtons}>
          <button
            data-value="true"
            onClick={handleHas}
            className={has ? styles.selected : styles.notSelected}
          >
            Yes
          </button>
          <button
            data-value="false"
            onClick={handleHas}
            className={has === false ? styles.selected : styles.notSelected}
          >
            No
          </button>
          <Errors show={showErrors} errors={hasErrors} />
        </div>

        <div className={styles.chronicConditionsCheckboxes}>
          {POSSIBLE_CONDITIONS.map((conditions) => (
            <div key={conditions} className={styles.chronicConditionsCheckbox}>
              <input
                type="checkbox"
                id={conditions}
                name="chronicConditions"
                value={conditions}
                checked={Boolean(
                  chronicConditions.find((i) => i === conditions),
                )}
                disabled={!has}
                onChange={handleFieldChange}
              />
              <label
                htmlFor={conditions}
                className={
                  has
                    ? styles.chronicConditionsCheckboxLabel
                    : styles.chronicConditionsCheckboxLabelDisabled
                }
              >
                {conditions}
              </label>
            </div>
          ))}
        </div>
        <Errors show={showErrors} errors={chronicConditionsErrors} />
      </form>
      <button
        type="submit"
        className={styles.chronicConditionsNextButton}
        form="chronic-conditions-form"
      >
        Next
      </button>
    </section>
  );
};

import { getEmail, getPartialBasicInformation } from "./basicInformation";
import { readState, StoredState, storeState } from "./storedState";

export type Macros = {
  carbohydrates: number;
  protein: number;
  fat: number
}

export type FoodType = {
  foodTime: string;
  foodName: string;
  quantity: string;
}

export type FoodDetail = {
  time: string;
  foodDetail: FoodType;
}

export type FoodTime = {
  [time: string]: FoodType[];
}

export type NutritionType = {
  [date: string]: FoodTime
}

export const storeNutritionDetail = (
  foodInformation: FoodDetail,
) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  
  const currentDate = new Date().toLocaleDateString("en-US").slice(0, 10);
  const { time } = foodInformation;
  const lowerCaseTime = time.toLocaleLowerCase();

  storeState({
    ...storedState,
    nutrition: {
      ...storedState.nutrition,
      [currentDate]: {
        ...storedState.nutrition[currentDate],
        [lowerCaseTime]: [
          ...storedState.nutrition[currentDate][lowerCaseTime],
          foodInformation.foodDetail
        ]
      },
    },
  });
};

export const getNutritionInformation = (storedState: StoredState): NutritionType | null => {
  const email = getEmail(storedState);
  if (!email) return null;

  const nutrition = storedState.nutrition ?? {};

  return nutrition;
};

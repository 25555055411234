import { AlreadyAccount } from "../../../components/AlreadyAccount";
import { nextWelcomeScreen } from "../nextWelcomeScreen";
import styles from "./WhatWeDo.module.css";
import Logo from "../../../assets/logo-color.png";

export const WhatWeDo = () => {
  const nextScreen = nextWelcomeScreen("WhatWeDo");
  return (
    <section className={styles.whatWeDoContainer}>
      <figure className={styles.whatWeDoLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.whatWeDoDescription}>
        <h2>What we do?</h2>
        <p>
          HealthLink360 utilizes state-of-the-art technology to offer tailored
          preventive health advice and resources. The more you engage, the more
          effective your prevention strategies become
        </p>
      </div>
      <div className={styles.whatWeDoNextContainer}>
        <a className={styles.whatWeDoNext} href={nextScreen}>
          Next
        </a>
        <AlreadyAccount />
      </div>
    </section>
  );
};

import { AlreadyAccount } from "../../../components/AlreadyAccount";
import { nextWelcomeScreen } from "../nextWelcomeScreen";
import styles from "./Welcome.module.css";
import Logo from "../../../assets/logo-color.png";

export const Welcome = () => {
  const nextScreen = nextWelcomeScreen("Welcome");
  return (
    <section className={styles.container}>
      <div className={styles.logoContainer}>
        <figure>
          <img src={Logo} alt="HealthLink360 logo" />
        </figure>
      </div>
      <div className={styles.welcomeContainer}>
        <h2>Welcome</h2>
        <p className={styles.welcomeParagraph}>
          Your trusted health partner is crafted to empower individuals like us
          with personalized care and support
        </p>
        <a className={styles.welcomeGetStarted} href={nextScreen}>
          Get Started
        </a>
        <AlreadyAccount />
      </div>
    </section>
  );
};

import { AlreadyAccount } from "../../../components/AlreadyAccount";
import { nextWelcomeScreen } from "../nextWelcomeScreen";
import styles from "./HowCanWeHelp.module.css";
import Logo from "../../../assets/logo-color.png";

export const HowCanWeHelp = () => {
  const nextScreen = nextWelcomeScreen("HowCanWeHelp");
  return (
    <section className={styles.howCanWeHelpContainer}>
      <figure className={styles.howCanWeHelpLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.howCanWeHelpDescription}>
        <h2>How Can We Help?</h2>
        <h3>Personalized Health Coaching</h3>
        <p>
          HealthLink360 offers personalized health coaching powered by Al
          technology, tailored to your unique needs and preferences.
        </p>
        <h3>Preventive Care Focus</h3>
        <p>
          Our app emphasizes preventive care, helping users identify and address
          potential health risks before they escalate into serious conditions.
        </p>
        <h3>Culturally Competent Support</h3>
        <p>
          We provide culturally competent support, ensuring that our users
          receive care that respects their cultural backgrounds and values.
        </p>
      </div>

      <div className={styles.howCanWeHelpNextContainer}>
        <a className={styles.howCanWeHelpNext} href={nextScreen}>
          Next
        </a>
        <AlreadyAccount />
      </div>
    </section>
  );
};

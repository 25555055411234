import { createRef, useEffect } from "react";
import { Navbar } from "../../../components/Navbar";
import styles from "./ScannerResults.module.css";

export const ScannerResults = () => {
  const videos = createRef<HTMLVideoElement>();

  const currentDate = new Date().toDateString();

  useEffect(() => {
    const stream = async () =>
      await navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((mediaStream) => {
          if (!videos) return;
          if (!videos.current) return;
          videos.current.srcObject = null;
          mediaStream.getTracks().forEach((track) => track.stop());
        });
    stream();
  });

  return (
    <section className={styles.scannerResultsContainer}>
      <div className={styles.title}>
        <h2>Hi, Kenneth</h2>
        <p>
          Last update <span>{currentDate}</span>
        </p>
      </div>
      <div className={styles.buttonsMainContainer}>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-heart"></i>
            </p>
            <p>Heart rate</p>
            <p>80 BPM</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-heart-pulse"></i>
            </p>
            <p>HRV</p>
            <p>60 MS</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-lungs"></i>
            </p>
            <p>Respiration rate</p>
            <p>15 RPM</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-brain"></i>
            </p>
            <p>Stress level</p>
            <p>Low</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-droplet"></i>
            </p>
            <p>Blood pressure</p>
            <p>136/97</p>
          </div>
        </div>
      </div>
      <a className={styles.navigationButton} href="/scanner/charts">
        Charts
      </a>
      <Navbar />
    </section>
  );
};

import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { getErrors } from "../../../lib/zod";
import styles from "./ResourcesOptions.module.css";
import Logo from "../../../assets/logo-color.png";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { storeResourcesOptions } from "../../../lib/storeFunctions";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { Errors } from "../../../components/Errors";

const RESOURCES = [
  "Finding affordable housing resources",
  "Transportation assistance programs",
  "Community support groups and social activities",
  "Healthy food pantries and meal planning tips",
  "None of the above",
] as const;

const ZResourcesOptions = z.object({
  resourcesOptions: z.array(z.enum(RESOURCES)),
});

export const ResourcesOptions = () => {
  const navigate = useNavigate();
  const [resourcesOptions, setResourcesOptions] = useState([] as string[]);
  const [showErrors, setShowErrors] = useState(false);

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValues = new Set(resourcesOptions);
    const { id, checked } = event.target;
    if (checked) {
      if (id === "Select all") {
        RESOURCES.filter((i) => i !== "None of the above").forEach((i) =>
          currentValues.add(i),
        );
      }

      if (id === "None") {
        currentValues.clear();
      } else {
        currentValues.delete("None");
      }

      currentValues.add(id);
    } else {
      currentValues.delete("Select all");
      currentValues.delete(id);
    }
    setResourcesOptions([...currentValues]);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zResourcesOptions = ZResourcesOptions.safeParse({
      resourcesOptions,
    });
    if (!zResourcesOptions.success) {
      setShowErrors(true);
      return;
    }

    const cleanSet = new Set(zResourcesOptions.data.resourcesOptions);
    cleanSet.delete("None of the above");
    storeResourcesOptions({ resourcesOptions: [...cleanSet] });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("ResourcesOptions"));
  };

  const zResourcesOptions = ZResourcesOptions.safeParse({
    resourcesOptions,
  });
  const familyChronicIllnessesErrors = getErrors(zResourcesOptions, [
    "resourcesOptions",
  ]);

  return (
    <section className={styles.resourcesOptionsContainer}>
      <figure className={styles.resourcesOptionsLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        className={styles.resourcesOptionsForm}
        id="resources-options-form"
      >
        <h2>Social Determinants of Health</h2>
        <h3>
          HealthLink360 can connect you with resources to make living healthy
          easier. Are you interested in exploring options for any of the
          following:
        </h3>
        <div className={styles.resourcesOptionsCheckboxes}>
          {RESOURCES.map((resource) => (
            <div key={resource} className={styles.resourcesOptionsCheckbox}>
              <input
                type="checkbox"
                id={resource}
                name="resourcesOptions"
                value={resource}
                checked={Boolean(resourcesOptions.find((i) => i === resource))}
                onChange={handleFieldChange}
              />
              <label htmlFor={resource}>{resource}</label>
            </div>
          ))}
        </div>
        <Errors show={showErrors} errors={familyChronicIllnessesErrors} />
      </form>
      <button
        type="submit"
        className={styles.resourcesOptionsNextButton}
        form="resources-options-form"
      >
        Next
      </button>
    </section>
  );
};

import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SignUpOtp.module.css";
import Logo from "../../assets/logo-color.png";

const validateOtp = (otp: string): string => {
  const expectedLength = 6;

  if (otp.length === expectedLength) return "";
  return `Expected OTP length: ${expectedLength}`;
};

export const SignUpOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    if (name !== "otp") {
      console.log(`Unexpected name: ${name}`);
      return;
    }

    setOtp(value);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    if (validateOtp(otp)) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);
    navigate("/bi/names");
  };

  const otpErrors = validateOtp(otp);
  return (
    <section className={styles.signUpOtpContainer}>
      <figure className={styles.signUpOtpLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form onSubmit={handleSubmit} className={styles.signUpOtpForm}>
        <label htmlFor="otp">Enter OTP</label>
        <input
          name="otp"
          id="otp"
          placeholder="_ _ _ _ _ _"
          value={otp}
          onChange={handleFieldChange}
        />
        {showErrors && otpErrors && <div>{otpErrors}</div>}
        <div className={styles.signUpOtpButtons}>
          <div className={styles.signUpOtpAnchor}>
            <p>Didn't received OTP?</p>
            <a href="/#">Resend OTP</a>
          </div>
        </div>
        <button type="submit" className={styles.signUpOtpButton}>
          Submit
        </button>
      </form>
    </section>
  );
};

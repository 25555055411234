import { FormEventHandler, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { getErrors } from "../../../lib/zod";
import styles from "./SDOH.module.css";
import Logo from "../../../assets/logo-color.png";
import { Errors } from "../../../components/Errors";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { storeSdoh } from "../../../lib/storeFunctions";

const ZSDOH = z.object({
  nutritiousFood: z.enum([
    "Very Easy",
    "Easy",
    "Moderate",
    "Difficult",
    "Very Difficult",
  ]),
  safeEnvironment: z.enum(["Always", "Often", "Sometimes", "Rarely Never"]),
});

export const SocialDeterminantsOfHealth = () => {
  const navigate = useNavigate();
  const [nutritiousFood, setNutritiousFood] = useState("");
  const [safeEnvironment, setSafeEnvironment] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleSdoh: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const {
      name,
      dataset: { value },
    } = event.currentTarget;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }
    if (name === "nutritiousFood") return setNutritiousFood(value);
    if (name === "safeEnvironment") return setSafeEnvironment(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zSDOH = ZSDOH.safeParse({
      nutritiousFood,
      safeEnvironment,
    });

    if (!zSDOH.success) {
      setShowErrors(true);
      return;
    }

    storeSdoh(zSDOH.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("SDOH"));
  };

  const zSDOH = ZSDOH.safeParse({
    nutritiousFood,
    safeEnvironment,
  });

  const nutritiousFoodErrors = getErrors(zSDOH, ["nutritiousFood"]);
  const safeEnvironmentErrors = getErrors(zSDOH, ["safeEnvironment"]);

  return (
    <section className={styles.sdohContainer}>
      <figure className={styles.sdohLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form onSubmit={handleSubmit} className={styles.sdohForm} id="sdoh-form">
        <h2>Social Determinants of Health</h2>
        <div className={styles.sdohFormQuestion}>
          <p>
            How would you rate your access to nutritious food on a daily basis?
          </p>
          <div className={styles.sdohFormButtons}>
            <div className={styles.sdohQuestionsContainer}>
              <button
                name="nutritiousFood"
                data-value="Very Easy"
                onClick={handleSdoh}
                className={
                  nutritiousFood === "Very Easy"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Very Easy
              </button>
              <button
                name="nutritiousFood"
                data-value="Easy"
                onClick={handleSdoh}
                className={
                  nutritiousFood === "Easy"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Easy
              </button>
            </div>
            <div className={styles.sdohQuestionsContainer}>
              <button
                name="nutritiousFood"
                data-value="Moderate"
                onClick={handleSdoh}
                className={
                  nutritiousFood === "Moderate"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Moderate
              </button>
              <button
                name="nutritiousFood"
                data-value="Difficult"
                onClick={handleSdoh}
                className={
                  nutritiousFood === "Difficult"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Difficult
              </button>
            </div>
            <div className={styles.sdohQuestionsContainer}>
              <button
                name="nutritiousFood"
                data-value="Very Difficult"
                onClick={handleSdoh}
                className={
                  nutritiousFood === "Very Difficult"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Very Difficult
              </button>
              <button className={styles.hiddenButton}></button>
            </div>
          </div>
          <Errors show={showErrors} errors={nutritiousFoodErrors} />
        </div>
        <div className={styles.sdohFormQuestion}>
          <p>Do you feel safe in your living environment?</p>
          <div className={styles.sdohFormButtons}>
            <div className={styles.sdohQuestionsContainer}>
              <button
                name="safeEnvironment"
                data-value="Always"
                onClick={handleSdoh}
                className={
                  safeEnvironment === "Always"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Always
              </button>
              <button
                name="safeEnvironment"
                data-value="Often"
                onClick={handleSdoh}
                className={
                  safeEnvironment === "Often"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Often
              </button>
            </div>
            <div className={styles.sdohQuestionsContainer}>
              <button
                name="safeEnvironment"
                data-value="Sometimes"
                onClick={handleSdoh}
                className={
                  safeEnvironment === "Sometimes"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Sometimes
              </button>
              <button
                name="safeEnvironment"
                data-value="Rarely Never"
                onClick={handleSdoh}
                className={
                  safeEnvironment === "Rarely Never"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Rarely Never
              </button>
              <Errors show={showErrors} errors={safeEnvironmentErrors} />
            </div>
          </div>
        </div>
      </form>
      <button type="submit" className={styles.sdohNextButton} form="sdoh-form">
        Next
      </button>
    </section>
  );
};

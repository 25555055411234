import { getEmail, getPartialBasicInformation } from "./basicInformation";
import { readState, StoredState, storeState } from "./storedState";

export type PhysicalActivityInformation = {
  activityName: string;
  intensity: string;
  time: number;
};

export type Goals = {
  steps: number;
  calories: number;
};

export type Activity = {
  [activityName: string]: PhysicalActivityInformation;
};

export const storePhysicalActivity = (
  activityInformation: PhysicalActivityInformation,
) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");

  storeState({
    ...storedState,
    physicalActivity: {
      ...storedState.physicalActivity,
      [activityInformation.activityName]: activityInformation,
    },
  });
};

export const getPhysicalActivities = (
  storedState: StoredState,
): Activity | null => {
  const email = getEmail(storedState);
  if (!email) return null;

  const activity = storedState.physicalActivity ?? {};

  return activity;
};

export const getGoals = (storedState: StoredState): Goals | null => {
  const email = getEmail(storedState);
  if (!email) return null;

  const goals = storedState.goals;

  return goals;
};

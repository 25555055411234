import styles from "./NutritionMethod.module.css";
import Logo from "../../../assets/logo-color.png";

export const NutritionMethod = () => {
  return (
    <section className={styles.nutritionMethodContainer}>
      <figure className={styles.nutritionMethodLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.nutritionMethodNutrition}>
        <h2>Nutrition360 Tracker Method</h2>
        <h3>How do you want to track your nutrition?</h3>
        <div className={styles.nutritionMethodButtonsContainer}>
          <div className={styles.nutritionMethodButtonContainer}>
            <a href="/#" data-value="Container Number">
              Container Number
            </a>
            <span className={styles.nutritionMethodInfo}>i</span>
          </div>
          <div className={styles.nutritionMethodButtonContainer}>
            <a href="/nutrition/nutrition-entry" data-value="Manual Entry">
              Manual Entry
            </a>
            <span className={styles.nutritionMethodInfo}>i</span>
          </div>
        </div>
      </div>
    </section>
  );
};

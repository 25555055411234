import styles from "./displayPhysicalActivity.module.css";
import Logo from "../../../assets/logo-color.png";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Navbar } from "../../../components/Navbar";
import { usePhysicalActivityInformation } from "../../../hooks/usePhysicalActivityInformation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const dataLine = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      data: [0, 25, 40, 81, 56, 55, 40],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const DisplayPhysicalActivity = () => {
  const activity = usePhysicalActivityInformation();

  return (
    <section className={styles.displayPhysicalContainer}>
      <figure className={styles.displayPhysicalActivityLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.displayPhysicalInnerContainer}>
        <h2>Activity Tracker</h2>
        <div className={styles.displayPhysicalCaloriesContainer}>
          <div>
            <p>Today</p>
            <p>1,975</p>
            <p>Calories remaining</p>
          </div>
          <button>Add to log</button>
        </div>
        <div className={styles.displayActivityGrid}>
          <div className={styles.activityInformation}>
            <p>
              <span>1800</span>
              <span>Goal</span>
            </p>
            <p>
              <span>+0</span>
              <span>Food</span>
            </p>
            <p>
              <span>-175</span>
              <span>Exercise</span>
            </p>
            <p>
              <span>-175</span>
              <span>Net</span>
            </p>
          </div>
          <div className={styles.stepsDisplay}>
            <input
              type="range"
              className={styles.stepsRange}
              min="0"
              max="10000"
              defaultValue="3000"
            />
            <div className={styles.stepsContainer}>
              <p>
                <span>8,000</span> steps remaining
              </p>
              <p>1,000</p>
            </div>
          </div>
        </div>
        <Line
          data={dataLine}
          options={options}
          className={styles.physicalActivityChart}
        />
      </div>
      <Navbar />
    </section>
  );
};

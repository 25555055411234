import { useStoredState } from "./useStoredState";
import {
  PartialBasicInformation,
  getPartialBasicInformation,
} from "../lib/basicInformation";

export const usePartialBasicInformation = ():
  | [string, PartialBasicInformation]
  | null => {
  const storedState = useStoredState();
  return getPartialBasicInformation(storedState);
};

import styles from "./inputPhysicalActivity.module.css";
import Logo from "../../../assets/logo-color.png";
import { FormEvent, useState } from "react";
import { z } from "zod";
import { storePhysicalActivity } from "../../../lib/physicalActivity";

const PHYSICAL_ACTIVITY = [
  {
    name: "Swimming",
    icon: "fa-solid fa-person-swimming",
  },
  {
    name: "Biking",
    icon: "fa-solid fa-person-biking",
  },
  {
    name: "Running",
    icon: "fa-solid fa-person-running",
  },
  {
    name: "Yoga",
    icon: "fa-solid fa-spa",
  },
  {
    name: "Baseball",
    icon: "fa-solid fa-baseball",
  },
  {
    name: "Walk",
    icon: "fa-solid fa-person-walking",
  },
  {
    name: "Soccer",
    icon: "fa-solid fa-futbol",
  },
  {
    name: "Hiking",
    icon: "fa-solid fa-person-hiking",
  },
];

const ZActivities = z.object({
  activityName: z.string().min(2).trim(),
  intensity: z.string().min(2).trim(),
  time: z.number().min(1),
});

export const InputPhysicalActivity = () => {
  const [isSelected, setIsSelected] = useState<string>("");
  const [custom, setIsCustom] = useState(false);
  const [customActivityName, setCustomActivityName] = useState<string>("");
  const [intensity, setIntensity] = useState<string>("");
  const [time, setTime] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleOpenActivity = (activityName: string) => {
    console.log(5);
    setIsSelected(activityName);
  };

  const handleSaveActivity = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("event", event);

    if (custom) {
      PHYSICAL_ACTIVITY.push({
        name: customActivityName,
        icon: "fa-solid fa-weight-hanging",
      });
    }

    const zActivities = ZActivities.safeParse({
      activityName: isSelected || customActivityName,
      intensity,
      time,
    });

    if (!zActivities.success) {
      setError(true);
      setErrorMessage(
        "There is an error. Please, check the values added and send them again.",
      );
      return;
    }

    setIsCustom(false);
    storePhysicalActivity(zActivities.data);
    setIsSelected("");
    setIntensity("");
    setTime(0);
    setError(false);
    setErrorMessage("");
  };

  return (
    <section className={styles.inputPhysicalActivityContainer}>
      <figure className={styles.inputPhysicalActivityLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.inputPhysicalActivityInformation}>
        <h2>Add your physical activity</h2>
        <p>
          You can select or add manually a physical activity and insert the
          duration and intensity of it.
        </p>
        <div className={styles.inputPhysicalActivities}>
          {PHYSICAL_ACTIVITY.map((item) => (
            <div
              key={item.name}
              className={styles.activityContainer}
              onClick={() => handleOpenActivity(item.name)}
            >
              <i className={item.icon}></i>
              <p>{item.name}</p>
              {isSelected === item.name && (
                <form
                  className={styles.activityDetailForm}
                  onSubmit={(event) => handleSaveActivity(event)}
                >
                  <div className={styles.activityDetail}>
                    <input
                      type="number"
                      placeholder="Enter the time"
                      onChange={(event) => setTime(Number(event.target.value))}
                    />
                    <select
                      name="intensity"
                      onChange={(event) => setIntensity(event.target.value)}
                    >
                      <option value="">Intensity</option>
                      <option value="low">Low</option>
                      <option value="moderate">Moderate</option>
                      <option value="intense">Intense</option>
                    </select>
                  </div>
                  <button type="submit">Save</button>
                  <button type="submit">Cancel</button>
                </form>
              )}
            </div>
          ))}
        </div>
        {custom && (
          <div className={styles.customActivityContainer}>
            <form
              className={styles.customActivityForm}
              onSubmit={(event) => handleSaveActivity(event)}
            >
              <input
                type="text"
                placeholder="Activity name"
                onChange={(event) => setCustomActivityName(event.target.value)}
              />
              <input
                type="number"
                placeholder="Enter the time"
                onChange={(event) => setTime(Number(event.target.value))}
              />
              <select
                name="intensity"
                onChange={(event) => setIntensity(event.target.value)}
              >
                <option value="">Intensity</option>
                <option value="low">Low</option>
                <option value="moderate">Moderate</option>
                <option value="intense">Intense</option>
              </select>
              <div className={styles.customActivityButtons}>
                <button type="submit">Save</button>
                <button type="submit">Cancel</button>
              </div>
            </form>
          </div>
        )}
        {error && (
          <p className={styles.physicalErrorMessage}>*{errorMessage}</p>
        )}
      </div>
      {!custom && (
        <div className={styles.addCustomActivityContainer}>
          <button onClick={() => setIsCustom(true)}>Custom activity</button>
          <a href="/activity/home">Finish</a>
        </div>
      )}
    </section>
  );
};

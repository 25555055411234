import styles from "./Nutrition.module.css";
import Logo from "../../../assets/logo-color.png";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { Navbar } from "../../../components/Navbar";
import { useNutrition } from "../../../hooks/useNutrition";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const dataLine = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Net carbs",
      data: [0, 25, 40, 81, 56, 55, 40],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

const dataDoughnut = {
  labels: ["Done", "To go"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19],
      backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const Nutrition = () => {
  const userNutrition = useNutrition();
  
  const currentDate = new Date().toLocaleDateString("en-US").slice(0, 10);
  console.log('currentDate', currentDate);
  

  return (
    <section className={styles.nutritionContainer}>
      <figure className={styles.nutritionLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      {userNutrition && (userNutrition[currentDate]["breakfast"].length === 0 && userNutrition[currentDate]["lunch"].length === 0 && userNutrition[currentDate]["dinner"].length === 0 && userNutrition[currentDate]["snack"].length === 0) && (
        <>
          <h2>Add your first meal!</h2>
          <Link to="/nutrition/nutrition-entry" className={styles.firstMeal}>+</Link>
        </>
      )}
      {userNutrition && (userNutrition[currentDate]["breakfast"].length > 0 ||  userNutrition[currentDate]["lunch"].length > 0 ||  userNutrition[currentDate]["dinner"].length > 0 ||  userNutrition[currentDate]["snack"].length > 0) && (
        <div className={styles.nutritionInfoContainer}>
          <h2>Macros</h2>
          <div className={styles.nutritionSlides}>
            <div className={styles.nutritionSlideContainer}>
              <p>Net Carbs</p>
              <div className={styles.doughnutCharContainer}>
                <Doughnut
                  data={dataDoughnut}
                  className={styles.doughnutContainer}
                />
                <span>100 left</span>
              </div>
            </div>
            <div className={styles.nutritionSlideContainer}>
              <p>Fat</p>
              <div className={styles.doughnutCharContainer}>
                <Doughnut
                  data={dataDoughnut}
                  className={styles.doughnutContainer}
                />
                <span>150 left</span>
              </div>
            </div>
            <div className={styles.nutritionSlideContainer}>
              <p>Protein</p>
              <div className={styles.doughnutCharContainer}>
                <Doughnut
                  data={dataDoughnut}
                  className={styles.doughnutContainer}
                />
                <span>300 left</span>
              </div>
            </div>
          </div>
          <div className={styles.nutritionChart}>
            <Line options={options} data={dataLine} />
          </div>
          <div className={styles.addFood}>
            <Link to="/nutrition/nutrition-entry" className={styles.addFoodButton}>Add food</Link>
          </div>
        </div>
      )}
      <Navbar />
    </section>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import { SignUp } from "./screens/SignUp";
import { SignUpOtp } from "./screens/SignUpOtp";
import { SignIn } from "./screens/SignIn";

import { Welcome } from "./screens/welcome/Welcome";
import { WhatWeDo } from "./screens/welcome/WhatWeDo";
import { HowCanWeHelp } from "./screens/welcome/HowCanWeHelp";
import { NotificationPermission } from "./screens/welcome/NotificationPermission";

import { Names } from "./screens/basicInformation/Names";
import { Basic } from "./screens/basicInformation/Basic";
import { HealthAndWellness } from "./screens/basicInformation/HealthAndWellness";
import { Habits } from "./screens/basicInformation/Habits";
import { FoodSensitivities } from "./screens/basicInformation/FoodSensitivities";
import { SocialDeterminantsOfHealth } from "./screens/basicInformation/SDOH";
import { ResourcesOptions } from "./screens/basicInformation/ResourcesOptions";
import { ComplexHealth } from "./screens/basicInformation/ComplexHealth";
import { Medications } from "./screens/basicInformation/Medications";
import { ChronicConditions } from "./screens/basicInformation/ChronicConditions";
import { HealthGoals } from "./screens/basicInformation/HealthGoals";
import { TellCoachGoals } from "./screens/basicInformation/TellCoachGoals";
import { Thanks } from "./screens/basicInformation/Thanks";

import { Home } from "./screens/Home";
import { ScannerVideo } from "./screens/Scanner/ScannerVideo";
import { ScannerWarning } from "./screens/Scanner/ScannerWarning";
import { ScannerResults } from "./screens/Scanner/ScannerResults";
import { ScannerCharts } from "./screens/Scanner/ScannerCharts";
import { SymptomsMessage } from "./screens/Symptoms/SymptomsMessage";
import { SymptomsList } from "./screens/Symptoms/SymptomsList";
import { Nutrition } from "./screens/Nutrition/NutritionHome";
import { NutritionMethod } from "./screens/Nutrition/NutritionMethod";
import { NutritionEntry } from "./screens/Nutrition/NutritionEntry";
import { InputPhysicalActivity } from "./screens/PhysicalActivity/InputPhysicalActivity";
import { DisplayPhysicalActivity } from "./screens/PhysicalActivity/DisplayPhysicalActivity";
import { Disclaimer } from "./screens/AICoach/Disclaimer";
import { AICoachChat } from "./screens/AICoach/AICoachChat";
import { Goals } from "./screens/Goals";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NavigationSections, useNavigateUser } from "./hooks/useNavigateUser";

const Screen = ({
  section,
  component: Component,
}: {
  section: NavigationSections;
  component: () => JSX.Element;
}): JSX.Element => {
  useNavigateUser(section);
  return <Component />;
};

// prettier-ignore
const router = createBrowserRouter([
  { path: "/", element: <Screen section="welcome" component={Welcome} /> },
  { path: "/what-we-do", element: <Screen section="welcome" component={WhatWeDo} /> },
  { path: "/how-can-we-help", element: <Screen section="welcome" component={HowCanWeHelp} /> },
  { path: "/notification-permission", element: <Screen section="welcome" component={NotificationPermission} /> },
  { path: "/sign-up", element: <Screen section="welcome" component={SignUp} /> },
  { path: "/sign-up-otp", element: <Screen section="welcome" component={SignUpOtp} /> },
  { path: "/sign-in", element: <Screen section="welcome" component={SignIn} /> },

  { path: "/bi/names", element: <Screen section="basicInformation" component={Names} /> },
  { path: "/bi/basic", element: <Screen section="basicInformation" component={Basic} /> },
  { path: "/bi/health-and-wellness", element: <Screen section="basicInformation" component={HealthAndWellness} /> },
  { path: "/bi/habits", element: <Screen section="basicInformation" component={Habits} /> },
  { path: "/bi/food-sensitivities", element: <Screen section="basicInformation" component={FoodSensitivities} /> },
  { path: "/bi/social-determinants-of-health", element: <Screen section="basicInformation" component={SocialDeterminantsOfHealth} /> },
  { path: "/bi/resources", element: <Screen section="basicInformation" component={ResourcesOptions} /> },
  { path: "/bi/complex-health", element: <Screen section="basicInformation" component={ComplexHealth} /> },
  { path: "/bi/medications", element: <Screen section="basicInformation" component={Medications} /> },
  { path: "/bi/chronic-conditions", element: <Screen section="basicInformation" component={ChronicConditions} /> },
  { path: "/bi/health-goals", element: <Screen section="basicInformation" component={HealthGoals} /> },
  { path: "/bi/tell-coach-goals", element: <Screen section="basicInformation" component={TellCoachGoals} /> },
  { path: "/bi/thanks", element: <Screen section="complete" component={Thanks} /> },

  { path: "/home", element: <Screen section="complete" component={Home} /> },
  { path: "/scanner/video", element: <Screen section="complete" component={ScannerVideo} /> },
  { path: "/scanner/warning", element: <Screen section="complete" component={ScannerWarning} /> },
  { path: "/scanner/results", element: <Screen section="complete" component={ScannerResults} /> },
  { path: "/scanner/charts", element: <Screen section="complete" component={ScannerCharts} /> },
  { path: "/symptoms/symptoms-message", element: <Screen section="complete" component={SymptomsMessage} /> },
  { path: "/symptoms/symptoms-list", element: <Screen section="complete" component={SymptomsList} /> },
  { path: "/nutrition/nutrition-method", element: <Screen section="complete" component={NutritionMethod} /> },
  { path: "/nutrition/nutrition-entry", element: <Screen section="complete" component={NutritionEntry} /> },
  { path: "/nutrition/nutrition-home", element: <Screen section="complete" component={Nutrition} /> },
  { path: "/activity/input-physical-activity", element: <Screen section="complete" component={InputPhysicalActivity} /> },
  { path: "/activity/home", element: <Screen section="complete" component={DisplayPhysicalActivity} /> },
  { path: "/coach/disclaimer", element: <Screen section="complete" component={Disclaimer} /> },
  { path: "/coach/ai-coach", element: <Screen section="complete" component={AICoachChat} /> },
  { path: "/goals", element: <Screen section="complete" component={Goals} /> },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  getPartialBasicInformation,
  HealthGoalsType,
} from "./basicInformation";
import { Goals } from "./physicalActivity";
import { readState, storeState } from "./storedState";

export const storeJwt = (jwt: string): void => {
  const storedState = readState();
  storeState({ ...storedState, jwt });
};

export const deleteJwt = (): void => {
  const storedState = readState();
  storeState({ ...storedState, jwt: null });
};

export const resetBasicInformation = (): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email] = pbi;

  storeState({
    ...storedState,
    basicInformation: { ...storedState.basicInformation, [email]: {} },
  });
};

export const storeNames = (names: {
  firstName: string;
  lastName: string;
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...names },
    },
  });
};

export const storeBasicOnboarding = (basic: {
  email: string;
  dob: Date;
  gender: string;
  zipCode: string;
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...basic },
    },
  });
};

export const storeBasicHealthAndWellness = (basic: {
  weight: string;
  height: string;
  activityLevel: string;
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...basic },
    },
  });
};

export const storeHabits = (habits: {
  stressLevel: string;
  smoke: boolean;
  alcohol: string;
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...habits },
    },
  });
};

export const storeFood = (food: {
  foodSensitivities: boolean;
  foodSensitivitiesDetails: string | null;
}) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...food },
    },
  });
};

export const storeSdoh = (sdoh: {
  nutritiousFood: string;
  safeEnvironment: string;
}) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...sdoh },
    },
  });
};

export const storeResourcesOptions = (resources: {
  resourcesOptions: string[];
}) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...resources },
    },
  });
};

export const storeComplexHealth = (complexHealth: {
  checkUpsFrequency: string;
}) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...complexHealth },
    },
  });
};

export const storeMedications = (medications: { medications: string[] }) => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...medications },
    },
  });
};

export const storeChronicConditions = (chronicCondition: {
  chronicCondition: string[];
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...chronicCondition },
    },
  });
};

export const storeHealthGoals = (healthGoals: {
  healthGoals: HealthGoalsType[];
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...healthGoals },
    },
  });
};

export const storeTellCoachGoals = (tellCoachGoals: {
  tellCoachGoals: string | null;
}): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");
  const [email, basicInformation] = pbi;

  storeState({
    ...storedState,
    basicInformation: {
      ...storedState.basicInformation,
      [email]: { ...basicInformation, ...tellCoachGoals },
    },
  });
};

export const storeGoals = (goals: Goals): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");

  storeState({
    ...storedState,
    goals: goals,
  });
};

import { z } from "zod";
import { StoredState } from "./storedState";
import { jwtDecode } from "jwt-decode";

export type HealthGoalsType = {
  name: string;
  description: string;
};

export type PartialBasicInformation = {
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  dob?: Date;
  zipCode?: string;
  weight?: string;
  height?: string;
  activityLevel?: string;
  stressLevel?: string;
  smoke?: boolean;
  alcohol?: string;
  foodSensitivities?: boolean;
  foodSensitivitiesDetails?: string | null;
  nutritiousFood?: string;
  safeEnvironment?: string;
  resourcesOptions?: string[];
  checkUpsFrequency?: string;
  medications?: string[];
  chronicCondition?: string[];
  healthGoals?: HealthGoalsType[];
  tellCoachGoals?: string | null;
};

const ZBasicInformation = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  gender: z.string(),
  dob: z.coerce.date(),
  zipCode: z.string(),
  weight: z.string(),
  height: z.string(),
  activityLevel: z.string(),
  stressLevel: z.string(),
  smoke: z.boolean(),
  alcohol: z.string(),
  foodSensitivities: z.boolean(),
  foodSensitivitiesDetails: z.string(),
  nutritiousFood: z.string(),
  safeEnvironment: z.string(),
  resourcesOptions: z.array(z.string()),
  checkUpsFrequency: z.string(),
  medications: z.string().optional().array(),
  chronicCondition: z.string().optional().array(),
  healthGoals: z.array(
    z.object({
      name: z.string(),
      description: z.string(),
    }),
  ),
  tellCoachGoals: z.string(),
});
export type BasicInformation = z.infer<typeof ZBasicInformation>;

export const getEmail = (storedState: StoredState): string | null => {
  const { jwt } = storedState;
  if (!jwt) return null;

  const decoded = jwtDecode(jwt);
  if (!("email" in decoded) || typeof decoded.email !== "string") return null;

  return decoded.email.trim().toLowerCase();
};

export const getPartialBasicInformation = (
  storedState: StoredState,
): [string, PartialBasicInformation] | null => {
  const email = getEmail(storedState);
  if (!email) return null;
  return [email, storedState.basicInformation[email] ?? {}];
};

export const getBasicInformation = (
  storedState: StoredState,
): [string, BasicInformation] | null => {
  const partialBasicInformation = getPartialBasicInformation(storedState);

  if (!partialBasicInformation) return null;

  const [email, basicInformation] = partialBasicInformation;

  const zBasicInformation = ZBasicInformation.safeParse(basicInformation);

  if (!zBasicInformation.success) return null;

  return [email, zBasicInformation.data];
};

type Props = {
  show?: boolean;
  errors: string[];
};

export const Errors = ({ show = true, errors }: Props) => {
  if (!show) return null;
  if (errors.length === 0) return null;

  return <span>* {errors.join(" - ")}</span>;
};

import { MouseEventHandler, useEffect } from "react";
import { nextWelcomeScreen } from "../nextWelcomeScreen";
import { useNavigate } from "react-router-dom";
import styles from "./NotificationPermission.module.css";
import Logo from "../../../assets/logo-color.png";

export const NotificationPermission = () => {
  const navigate = useNavigate();
  const nextScreen = nextWelcomeScreen("NotificationPermission");
  useEffect(() => {
    if (!("Notification" in window)) return;

    if (["granted", "denied"].includes(Notification.permission)) {
      navigate(nextScreen);
      return;
    }
  }, [navigate, nextScreen]);

  const handleAllow: MouseEventHandler = async (event) => {
    event.preventDefault();

    await Notification.requestPermission();
    navigate(nextScreen);
  };

  return (
    <section className={styles.notificationPermissionContainer}>
      <figure className={styles.notificationPermissionLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <h2>Notification Permission</h2>
      {/* Modal */}
      <div className={styles.notificationPermissionBox}>
        <p>"HealthLink360" would like to send you notifications</p>
        <div className={styles.notificationPermissionButtons}>
          {/* Can we change this two tags to be using the same tag? */}
          {/* Ex: Both buttons */}
          <button onClick={handleAllow}>Allow</button>
          <a href={nextScreen}>Don't Allow</a>
        </div>
      </div>
    </section>
  );
};

import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { storeJwt } from "../../lib/storeFunctions";
import { useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import Logo from "../../assets/logo-color.png";

const validateEmail = (email: string): string => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (re.test(email)) return "";
  return "Invalid Email";
};

const validatePassword = (password: string): string => {
  const minimum = 4;

  if (password.length >= minimum) return "";
  return `Minimum password length: ${minimum}`;
};

export const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    if (name !== "email" && name !== "password") {
      console.log(`Unexpected name: ${name}`);
      return;
    }

    if (name === "email") {
      setEmail(value);
      return;
    }
    if (name === "password") {
      setPassword(value);
      return;
    }
  };

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    if (validateEmail(email) || validatePassword(password)) {
      setShowErrors(true);
      return;
    }

    setLoading(true);
    const apiEndpoint = new URL("/users", process.env.REACT_APP_API_BASE_URL);

    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: JSON.stringify({ email, password }),
    });
    if (!response.ok) {
      const responseText = await response.text();
      console.log("API error", responseText);

      let parsedResponse;
      try {
        parsedResponse = JSON.parse(responseText);
      } catch (error) {
        console.error(error);

        setApiError("Unexpected error");
        setShowErrors(true);
        setLoading(false);
        return;
      }

      setApiError(
        response.status === 422 && parsedResponse.code === "EMAIL_TAKEN"
          ? "Email was already taken"
          : "Unexpected error",
      );
      setShowErrors(true);
      setLoading(false);
      return;
    }

    const { jwt } = await response.json();
    storeJwt(jwt);
    setApiError("");
    setShowErrors(false);
    setLoading(false);
    navigate("/sign-up-otp");
  };

  const emailErrors = validateEmail(email);
  const passwordErrors = validatePassword(password);
  const formErrors = apiError;

  return (
    <section className={styles.signUpContainer}>
      <figure className={styles.signUpLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <h1>Register Now</h1>
      <form onSubmit={handleSubmit} className={styles.signUpForm}>
        <p>Please register your account to get started</p>
        <div className={styles.signUpFormInputs}>
          <div className={styles.signUpFormInput}>
            <input
              name="email"
              placeholder="Email Address"
              value={email}
              disabled={loading}
              onChange={handleFieldChange}
            />
            {!loading && showErrors && emailErrors && (
              <span>* {emailErrors}</span>
            )}
          </div>
          <div className={styles.signUpFormInput}>
            <input
              name="password"
              placeholder="Password"
              type="password"
              value={password}
              disabled={loading}
              onChange={handleFieldChange}
            />
            {!loading && showErrors && passwordErrors && (
              <span>* {passwordErrors}</span>
            )}

            {!loading && showErrors && formErrors && (
              <span>* {formErrors}</span>
            )}
          </div>
          <div className={styles.signInButtonContainer}>
            Already have an account? <a href="/sign-in">Sign in</a>
          </div>
        </div>
        <div className={styles.signUpAppsContainer}>
          <button>Continue with Google</button>
          <button>Continue with Apple</button>
        </div>
        <button
          type="submit"
          disabled={loading}
          className={styles.signUpRegister}
        >
          Register
        </button>
      </form>
    </section>
  );
};

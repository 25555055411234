import styles from "./SymptomsMessage.module.css";
import Logo from "../../../assets/logo-color.png";

export const SymptomsMessage = () => {
  return (
    <section className={styles.symptomsContainer}>
      <figure className={styles.symptomsLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <div className={styles.symptomsBox}>
        <h2>Symptoms</h2>
        <p>
          HealthLink360 Symptoms Tracker enables you to track your health and
          take proactive measures to protect yourself and others.
        </p>
      </div>
      <div className={styles.symptomsButtonContainer}>
        <a href="/symptoms/symptoms-list" className={styles.symptomsButton}>
          Got it
        </a>
      </div>
    </section>
  );
};
